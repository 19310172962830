import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerWorkflow'

import equipment_module from '../assets/images/equipment_module.jpg'
import pm_module from '../assets/images/pm_module.jpg'
import repair_module from '../assets/images/repair_module.jpg'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>MedEMS | Medical Equipment Workflow Management System</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        <BannerLanding />

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Standard modules</h2>
                    </header>
                </div>
            </section>
            <section id="two" className="spotlights">
                <section>
                    <Link to="https://demo.medems.ie/" className="image">
                        <img src={equipment_module} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Equipment Inventory Module</h3>
                            </header>
                            <p>The Medical Equipment Workflow Management System makes inventory management more efficient. This module controls the equipment master list, which includes site and location details. Users can monitor equipment history and maintenance records and can track equipment by asset number, serial number, and make/model information.</p>
                            <ul className="actions">
                                <li><a href="https://demo.medems.ie/" className="button" target="_blank" rel="noreferrer">Try</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="https://demo.medems.ie/" className="image">
                        <img src={pm_module} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Preventative Maintenance Module</h3>
                            </header>
                            <p>The EWMS enables generation of preventive maintenance records based on the type of equipment and manufacturers recommended PPM procedures. The system users are able to create, track, complete and close PM records depend on their level of access. Completed records can be emailed to the customer or converted to PDF for further analysis.</p>
                            <ul className="actions">
                                <li><a href="https://demo.medems.ie/" className="button" target="_blank" rel="noreferrer">Try</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="https://demo.medems.ie/" className="image">
                        <img src={repair_module} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Corrective Maintenance/Repair Module</h3>
                            </header>
                            <p>The system allows the creation of corrective maintenance records based on call outs. The corrective maintenance records contain request information, equipment inventory details and a description of the work that was carried out. Completed repair records can be sent by email, printed or exported to PDF.</p>
                            <ul className="actions">
                                <li><a href="https://demo.medems.ie/" className="button" target="_blank" rel="noreferrer">Try</a></li>
                            </ul>
                        </div>
                    </div>
                </section>
            </section>
            <section id="three">
                <div className="inner">
                    <header className="major">
                        <h2>Optional modules</h2>
                    </header>
                    <ul>
                        <li><strong>Parts Management Module</strong> - integrated into the Preventative and Corrective Maintenance sections. Based on monitoring parts usage during service procedures, the module accumulates this information and makes it usable for other areas and departments (Accounts, Parts Stores, etc).</li>
                        <li><strong>Customer Relationship Management</strong> - allows designated users to have read access to their sites records.</li>
                        <li><strong>Service Contract Management Module.</strong></li>
                        <li><strong>Performance Reporting Module.</strong></li>
                        <li><strong>Dispatch and Logistics Module.</strong></li>
                        <li><strong>Account Management Module.</strong></li>
                    </ul>
                </div>
            </section>
        </div>

    </Layout>
)

export default Landing